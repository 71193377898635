<template>
  <v-container>
    <v-breadcrumbs
      class="py-2"
      :items="[
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'Dashboard' },
        },
        {
          text: `Chantier`,
          disabled: false,
          exact: true,
          to: loading
            ? {}
            : { name: 'SiteElevators', params: { id: elevator.site.id } },
        },
        {
          text: elevator.code ? `Ascenseur n°${elevator.code}` : 'Ascenseur',
          disabled: true,
        },
      ]"
    />
    <v-progress-linear v-if="loading" class="mt-2" indeterminate />
    <v-card v-else class="mt-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <div class="d-flex align-center">
              <v-icon size="4em" class="mr-4">mdi-elevator</v-icon>
              <span class="text-h4 font-weight-regular" v-if="elevator.code">
                Ascenseur n°{{ elevator.code }}
              </span>
            </div>
            <div class="mt-4">
              <div>
                <v-icon small left>mdi-format-list-bulleted</v-icon>
                <span v-if="!elevator.levels"> ??? </span>
                <span v-if="elevator.levels === 1"> Un unique niveau </span>
                <span v-if="elevator.levels > 1">
                  {{ elevator.levels }} niveaux
                </span>
              </div>
              <div class="mt-2">
                <v-icon small left>mdi-cogs</v-icon>
                <span>Constructeur : {{ elevator.constructor.name }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="d-flex justify-center">
              <v-btn
                v-if="$store.state.auth.user.isAdmin"
                color="info"
                text
                @click="$refs.editForm.open(elevator)"
              >
                <v-icon left>mdi-pencil</v-icon> Modifier
              </v-btn>
              <v-btn
                v-if="$store.state.auth.user.isAdmin"
                color="error"
                text
                @click="$refs.removeForm.open(elevator)"
              >
                <v-icon left>mdi-delete</v-icon> Supprimer
              </v-btn>
            </div>
            <v-btn
              block
              class="mt-2"
              color="primary"
              text
              @click="$refs.archiveForm.generate('elevator', elevator)"
            >
              <v-icon left> mdi-archive-arrow-down </v-icon>
              Télécharger l'archive
            </v-btn>
            <v-btn
              v-if="$store.state.debugMode"
              block
              class="mt-2"
              color="secondary"
              text
              @click="fetchData"
            >
              <v-icon left>mdi-refresh</v-icon> Rafraîchir
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-3" v-if="elevator.id">
      <v-tabs v-model="activeTab" exact centered>
        <v-tab :to="{ name: 'ElevatorAudit' }" exact> Audit </v-tab>
        <v-tab
          v-if="$store.state.auth.user.isAdmin"
          :to="{ name: 'ElevatorUnloading' }"
          exact
        >
          Remplissage
        </v-tab>
        <v-tab :to="{ name: 'ElevatorDocuments' }" exact> Documents RSE </v-tab>
        <v-tab :to="{ name: 'ElevatorCertificates' }" exact>
          Attestation de collecte
        </v-tab>
      </v-tabs>
      <router-view :elevator="elevator" @reload="fetchData"></router-view>
    </v-card>
    <EditElevator ref="editForm" @finished="fetchData" />
    <RemoveItem
      ref="removeForm"
      resource="elevators"
      title="cet ascenseur"
      @finished="
        $router.push({
          name: 'SiteElevators',
          params: { id: elevator.site.id },
        })
      "
    />
    <ArchiveForm ref="archiveForm" />
  </v-container>
</template>

<script>
import RemoveItem from "@/components/RemoveItem.vue";
import EditElevator from "@/components/Elevators/EditElevator";
import ArchiveForm from "@/components/ArchiveForm";

export default {
  components: {
    ArchiveForm,
    EditElevator,
    RemoveItem,
  },
  data: () => ({
    activeTab: "",
    elevator: {
      site: { id: "" },
    },
    loading: true,
  }),
  mounted() {
    this.fetchData();
    this.refreshTab();
    if (this.$route.name === "ViewElevator") {
      this.$router.push({
        name: "ContainerCycles",
        params: { id: this.$route.params.id },
      });
    }
  },
  computed: {},
  watch: {
    "$route.path"() {
      this.refreshTab();
    },
  },
  methods: {
    refreshTab() {
      this.activeTab = this.$route.path;
    },
    fetchData() {
      this.loading = true;
      this.$store
        .dispatch("elevators/fetchOne", this.$route.params.id)
        .then((res) => {
          this.loading = false;
          this.elevator = res;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.$store.commit("alert/add", {
            type: "error",
            message: "Impossible de charger l'ascenseur",
          });
        });
    },
  },
};
</script>
